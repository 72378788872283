<template>
  <div class="content">
    <headeBar left-arrow :title="header_title" :background="header_background" :opacity="header_opacity"
      :placeholder="false" @click-left="newAppBack" ref="head">
      <template slot="left">
        <van-icon name="arrow-left left_icon" :color="half ? '#000' : '#fff'" />
      </template>
    </headeBar>
    <div class="page-content">
      <div class="rule">
        <div @click="shareFun">分享</div>
        <div @click='goRule'>规则</div>
      </div>
      <div class="flag slide-top" @click="goFlag">
        <span class="col-start-center" v-if="!hasFlag">
          <span>点击</span>
          <span class="f34 fw6 mt10">立运动flag</span>
        </span>
        <span v-else class="col-start-center">
          <span class="f36">{{ flagName }}</span>
          <span class="f30 fw4 mt5">我的flag</span>
        </span>
      </div>
      <count-down v-if="remainTimestamp > 0" :timeObj="{ diffEndTime: remainTimestamp }" status="in" />
      <p v-else class="end">活动已结束</p>
      <div class="gift">
        <p class="g-title">已打卡 {{ count }} / 30 节</p>
        <div class="progress">
          <div class="line">
            <span class="line-p" :style="{ width: (count >= 30 ? '100%' : ((count / 30) * 100 + '%')) }"></span>
          </div>
          <div class="point">
            <div>
              <img :src="baseImgUrl + 'c-start.png'" alt="">
              <p>进度</p>
            </div>
            <div v-for=" i  in  3 ">
              <img :src="baseImgUrl + 'c-check' + (count >= i * 10 ? '.png' : 'Out.png')">
              <p>{{ i * 10 }}节</p>
            </div>
          </div>
        </div>
        <div class="gift-box">
          <div v-for="(i, key) in giftList" class="gift-item">
            <img :src="i.picUrl" alt="">
            <div class="g-c">
              <p class="line1">打卡满<span>{{ (key + 1) * 10 }}</span>节</p>
              <p class="line2">
                <span class="gn">
                  <span>送</span>
                  <span>{{ i.awardName }}</span>
                </span>
                <span class="btns">
                  <span v-if="i.awardStatus == 'RECEIVED'" @click="showGiftDetai(i)">查看奖品</span>
                  <span v-else-if="i.awardStatus == 'RECEIVABLE'" @click="getGift(i)">领取</span>
                  <span v-else class="dis">未达到</span>
                </span>
              </p>
              <p class="line3">价值{{ i.cashValue }}元</p>
              <p class="tips" v-if="count < (key + 1) * 10 && count >= key * 10">还差{{ (key + 1) * 10 - count
              }}节就可以领取奖励啦！快去约课吧~
              </p>
            </div>
          </div>
          <div class="empty" v-if="giftList.length == 0">
            <img src="https://chaolu.oss-cn-hangzhou.aliyuncs.com/ACT/flag-202404/empty.png" alt="">
            <p class="mt10">暂未获取到登录信息</p>
          </div>
        </div>
      </div>
      <div class="rule-img" ref="ruleRef">
        <img :src="baseImgUrl + 'rules.png'" alt="">
      </div>
      <div class="go-btn ">
        <div class="btn2">
          <div @click="gotoAppPage(3)">去约课</div>
        </div>
      </div>
    </div>
    <!-- 分享 -->
    <common-share :value="showShare" :shareParams="shareParams" @close="showShare = false"
      @share-success="showShare = false" :miniSharePoster="appTypeStr === 'mini'" :reload="true">
      <template slot="createDom">
        <div class="canvascss">
          <img :src="baseImgUrl + 'sharebg.png'" class="canvascss_bg" />
          <div class="canvascss_left">
            <img :src="userDetail.headImg" />
          </div>
          <div class="canvascss_name ellipsis">
            {{ userDetail.nickName }}
          </div>
          <div class="canvascss_right">
            <img v-if="userDetail.qrCode" :src="'data:image/png;base64,' + userDetail.qrCode" />
          </div>
        </div>
      </template>
    </common-share>
    <!-- 查看奖品 -->
    <van-popup v-model="giftRecordShow" round position="bottom" safe-area-inset-bottom class="pop" closeable
      :close-icon="baseImgUrl + 'close.png'">
      <div class="title"><span class="bg"><span>领取记录</span></span></div>
      <div class="font">
        <p>奖品已发放至您的账户，</p>
        <p class="mt10">请到选择的门店找教练兑换领取</p>
      </div>
      <div class="line"></div>
      <div class="detail">
        <img :src="giftDetail.picUrl" alt="">
        <div>
          <p class="f28 fw6">{{ giftDetail.awardName }}</p>
          <p class="f24 mt30">兑换门店：{{ giftDetail.venueName }}</p>
        </div>
      </div>
    </van-popup>
    <!-- flag -->
    <van-popup v-model="flagShow" safe-area-inset-bottom class="flag-pop"
      :overlay-style="{ 'backdrop-filter': 'blur(10px)' }">
      <div class="title">
        <img :src="baseImgUrl + 'b-title.png'" alt="">
      </div>
      <div class="b-box">
        <span v-for="i in flagList" @click="checkB(i)" :class="i.flagId == flagId ? 'check' : ''">{{ i.flagName
        }}</span>
      </div>
      <div class="btns">
        <span class="btn1" @click="closeB"><span>再考虑下</span></span>
        <span class="btn2" @click="choseB"><span>就选ta了</span></span>
      </div>
    </van-popup>
    <!-- first弹窗 -->
    <van-popup v-model="isFirst" class="first-pop">
      <img :src="baseImgUrl + 'first.png'" class="first" alt="" @click="flagShow = true; isFirst = false">
      <img :src="baseImgUrl + 'close2.png'" alt="" class="close" @click="isFirst = false">
    </van-popup>
  </div>
</template>

<script>
import headeBar from '@/components/app/headBar';
import commonShare from "@/components/commonShare";
import countDown from "@/components/count-down";
import userMixin from "@/mixin/userMixin";
import headerMixin from '@/mixin/headerMixin'
import wx from "weixin-js-sdk";
import { newAppBack, initBack, gotoAppPage } from '@/lib/appMethod';
export default {
  components: {
    headeBar,
    commonShare, countDown
  },
  mixins: [userMixin, headerMixin],
  data() {
    return {
      baseImgUrl: 'https://img.chaolu.com.cn/ACT/flag-202404/',
      half: false,
      actTime: {},
      remainTimestamp: 0,
      ruleShow: false,
      hasFlag: false,
      isFirst: false,
      flagName: '',
      flagId: '',
      flagShow: false,
      flagList: [],
      ruleHeight: 0,
      // 活动数据
      count: 0,
      giftList: [],
      giftRecordShow: false,
      giftDetail: {},
      couponObj: { activityHeadImgs: [] },
      // 分享相关
      shareTag: true,
      userDetail: {},
      showShare: false,
      shareParams: {
        title: "团课打卡，最高得666元现金",
        miniImage: "https://img.chaolu.com.cn/ACT/flag-202404/mini.png",
        path: "/pages/webView/index?webUrl=" + window.location.origin + "/#/superdeer-activity/flag-202404&userId=1",
        miniProgramType: window.location.origin.indexOf('web.chaolu.com') > -1 ? '0' : '2', // 正式0，开发1，体验2
        url: "url",
        multiple: "0.95",
        userName: "gh_7f5b57b6a76e",
      }
    }
  },
  mounted() {
    window.scrollTo(0, 0)
    setTimeout(() => {
      this.ruleHeight = this.$refs.ruleRef.getBoundingClientRect().top
    }, 1000)
    this.bindHeaderScroll(window, 200, (scrollTop, morehalf) => {
      if (morehalf) {
        this.headerClass = '';
        this.half = true
      } else {
        this.headerClass = 'leftbg'
        this.half = false
      }

    });
  },
  async created() {
    initBack()
    await this.$getAllInfo(['userId', 'token'])
    this.init();
    if (this.appTypeStr === "mini") {
      const uu = encodeURIComponent(`${window.location.origin}/#/superdeer-activity/flag-202404`)
      this.shareParams.path = `/pages/webView/index?webUrl=${uu}`
      wx.miniProgram.postMessage({
        data: {
          type: "share",
          shareImage: this.shareParams.miniImage,
          shareTitle: this.shareParams.title,
          shareUrl: this.shareParams.path,
        },
      });
      this.countPoint('401', '401', '40002')
      return false;
    } else {
      this.countPoint('401', '401', '40003')
    }
  },
  methods: {
    newAppBack,
    gotoAppPage,
    init() {
      this.actInfo()
      this.getDreamFun()
      this.getGiftFun()
    },
    actInfo() {
      this.$axios.post(`${this.baseURLApp}/anniversary/sixth/getMainActivity`, { userId: this.userId }).then((res) => {
        const { isSetUp, startTime, endTime, flagName, isFirst } = res.data
        this.hasFlag = isSetUp
        this.isFirst = isFirst
        this.actTime = { beginTime: startTime, endTime }
        this.remainTimestamp = res.data.remainTimestamp
        let day = this.remainTimestamp / 1000 / 60 / 60 / 24
        if (day < -21) {
          // 活动已经下线提示活动已结束
          this.$dialog.alert({
            message: ' <b>很抱歉，该活动已经结束！<br/>去看看超鹿其他的活动吧</b>',
            confirmButtonText: '我知道了',
            className: 'dialog',
            overlayStyle: { backgroundColor: 'rgba(0,0,0,1)' }
          }).then(() => {
            if (this.appTypeStr === 'mini') {
              wx.miniProgram.switchTab({
                url: `/pages/home/index`
              })
            } else {
              this.appBack()
            }
          })
        }
        if (this.hasFlag) {
          this.flagName = flagName
        } else {
          this.getFlag()
        }
      })
    },
    // flag
    goFlag() {
      if (!this.userId) {
        this.$store.commit('openLoginPopup', true)
        return
      }
      if (this.hasFlag) {
        this.$toast('你已经立过flag了哦')
      } else {
        this.flagShow = true
      }
    },
    getFlag() {
      this.$axios.post(`${this.baseURLApp}/anniversary/sixth/getAllFlag`, {}).then((res) => {
        this.flagList = res.data
      })
    },
    checkB(flag) {
      this.flagId == flag.flagId ? this.flagId = '' : this.flagId = flag.flagId
    },
    closeB() {
      this.flagShow = false
      setTimeout(() => {
        this.flagId = ''
      }, 1000);
    },
    choseB() {
      if (!this.flagId) {
        this.$toast('请选择你的flag')
        return
      }
      let d = { ...this.flagList.filter(item => item.flagId == this.flagId)[0], userId: this.userId }
      this.$axios.post(`${this.baseURLApp}/anniversary/sixth/setUpFlag`, d).then((res) => {
        this.flagShow = false
        this.hasFlag = true
        this.flagName = d.flagName
      })
    },
    // act1
    getDreamFun() {
      this.$axios.post(`${this.baseURLApp}/anniversary/sixth/queryEfficientLesson`, { userId: this.userId }).then((res) => {
        this.count = res.data
      })
    },
    // act2
    getGiftFun() {
      this.$axios.post(`${this.baseURLApp}/anniversary/sixth/getUserGroupAward`, { userId: this.userId }).then((res) => {
        this.giftList = res.data
      })
    },
    showGiftDetai(i) {
      this.giftRecordShow = true
      this.giftDetail = i
    },
    getGift(i) {
      this.$router.push({ path: '/superdeer-activity/flag-202404/getGift', query: { awardId: i.awardId, picUrl: i.picUrl, awardName: i.awardName } })
    },
    goRecord() {
      this.$router.push({ path: '/superdeer-activity/flag-202404/record', query: this.actTime })
    },
    goRule() {
      window.scrollTo({ top: this.ruleHeight - 120, letf: 0, behavior: 'smooth' })
    },
    shareFun() {
      if (this.shareTag) {
        this.initQr();
      } else {
        this.showShare = true
      }
    },
    initQr() {
      this.$axios.post(`${this.baseURLApp}/qrCode/createOrdinaryCode`, {
        token: this.token,
        userId: this.userId,
        width: 240,
        height: 240,
        webUrl: 'https://web.chaolu.com.cn/#/ordinary_004001',
        scene: JSON.stringify({
          source: `flag-202404`,
          userId: 1,
          webUrl: `${window.location.origin}/#/superdeer-activity/flag-202404`
        }),
      }).then((res) => {
        this.userDetail = res.data;
        this.shareTag = false
        this.showShare = true
      });
    },
  }
}
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}

/deep/.van-popup__close-icon--top-right {
  top: 46px;
}

.content {
  background: #C898FC url(https://img.chaolu.com.cn/ACT/flag-202404/headbg.png) top/100% auto no-repeat;
  min-height: 100vh;

  .slide-top {
    animation: slide-top 2s linear infinite alternate;
  }

  @keyframes slide-top {
    0% {
      transform: translateY(0);
    }

    100% {
      transform: translateY(-10px);
    }
  }

  .page-content {
    padding-top: 740px;
    padding-bottom: 153px;
    padding-bottom: calc(constant(safe-area-inset-bottom) + 153px);
    padding-bottom: calc(env(safe-area-inset-bottom) + 153px);

    .rule {
      position: absolute;
      right: 0px;
      top: 230px;

      >div {
        width: 88px;
        height: 56px;
        line-height: 56px;
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 28px 0px 0px 28px;
        font-size: 24px;
        color: #fff;
        padding-left: 25px;
      }

      div+div {
        margin-top: 32px;
      }
    }

    .flag {
      width: 350px;
      height: 350px;
      border-radius: 50%;
      background: url(https://img.chaolu.com.cn/ACT/flag-202404/bubble.png) top/100% auto no-repeat;
      margin: 0 auto 40px;
      display: flex;
      justify-content: center;

      >span {
        font-size: 44px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
      }
    }

    .end {
      text-align: center;
      font-size: 36px;
      color: #fff;
      font-weight: 600;
    }

    .gift {
      width: 686px;
      height: 1013px;
      margin: 50px auto 60px;
      background: url(https://img.chaolu.com.cn/ACT/flag-202404/bg2.png?v=1) top/100% auto no-repeat;

      .g-title {
        padding-top: 104px;
        font-size: 30px;
        color: #fff;
        text-align: center;
        font-weight: 700;
      }

      .progress {
        margin: 36px auto 0px;
        width: 542px;
        position: relative;

        .line {
          height: 10px;
          background-color: #97BDFD;

          .line-p {
            position: absolute;
            top: 0;
            height: 10px;
            display: inline-block;
            background-color: #fff;
            border-radius: 10px;
          }
        }

        .point {
          display: flex;
          font-size: 22px;
          color: #fff;
          position: absolute;
          top: -20px;

          div {
            width: 90px;

            img {
              width: 50px;
              height: 50px;
            }

            position: absolute;

            &:nth-child(1) {
              left: -28px;
            }

            &:nth-child(2) {
              left: 168px
            }

            &:nth-child(3) {
              left: 348px;
            }

            &:nth-child(4) {
              left: 518px
            }
          }
        }
      }

      .gift-box {
        margin-top: 80px;

        .gift-item {
          margin: 0px auto 20px;
          width: 626px;
          background-color: #fff;
          border-radius: 30px;
          padding: 19px 21px;
          display: flex;

          img {
            width: 170px;
            height: 170px;
            margin-right: 19px;
          }

          .g-c {
            flex: 1;

            .line1 {
              font-size: 28px;
              font-weight: 600;
              margin-top: 14px;

              span {
                font-size: 54px;
                font-family: BebasNeueBold;
                margin: 0px 5px;
              }
            }

            .line2 {
              display: flex;

              .gn {
                position: relative;
                margin-top: 10px;
                margin-right: 10px;
                flex: 1;

                >span:nth-child(1) {
                  position: absolute;
                  left: 0px;
                  top: 0px;
                  font-size: 28px;
                  font-weight: 600;
                  color: #fff;
                  display: inline-block;
                  width: 50px;
                  height: 50px;
                  line-height: 50px;
                  padding-left: 10px;
                  border-radius: 50px;
                  background-color: #2066DA
                }

                >span:nth-child(2) {
                  display: inline-block;
                  font-size: 24px;
                  font-weight: 600;
                  font-family: AlibabaPuHuiTiH;
                  color: #242831;
                  background: linear-gradient(0deg, #E2ECFF 0%, #EDF3FF 100%);
                  border-radius: 10px;
                  padding: 0px 19px 0px 38px;
                  line-height: 44px;
                  margin-left: 22px;
                  margin-top: 6px;
                  height: 44px;
                }
              }

              .btns {
                display: flex;
                align-items: center;
                margin-top: 10px;
                font-weight: 700;

                >span {
                  height: 52px;
                  width: 120px;
                  background-color: #FD5900;
                  border-radius: 10px;
                  font-size: 22px;
                  color: #fff;
                  line-height: 52px;
                  text-align: center;

                  &.dis {
                    opacity: 0.5;
                  }
                }
              }
            }

            .line3 {
              font-size: 20px;
              margin-top: 14px;
            }

            .tips {
              margin-top: 16px;
              font-size: 20px;
              color: #FD5900;
            }
          }
        }
      }

      .empty {
        color: #fff;
        font-size: 34px;
        text-align: center;
        margin-top: 110px;

        img {
          width: 320px;
          height: 320px;
        }
      }
    }

    .rule-img {
      width: 100%;
      display: inline-block;
      text-align: center;

      img {
        width: 686px;
      }
    }

    .go-btn {
      display: flex;
      justify-content: center;
      padding: 20px 32px;
      width: 100%;
      position: fixed;
      bottom: 0px;
      padding-bottom: calc(constant(safe-area-inset-bottom) + 20px);
      padding-bottom: calc(env(safe-area-inset-bottom) + 20px);
      background: linear-gradient(0deg, #C898FC 0%, #DAB8FF 100%);

      >div {
        width: 666px;
        height: 90px;
        line-height: 90px;
        border-radius: 90px;

        >div {
          text-align: center;
          font-size: 40px;
          font-family: AlibabaPuHuiTiH;
          font-weight: 600;
          background: linear-gradient(0deg, #5F2600 0%, #993D00 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        &.btn1 {
          background: linear-gradient(0deg, #FFD9C0 0%, #FFF7F1 100%);
          ;
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1), 0px 2px 8px 0px rgba(255, 255, 255, 0.7);
        }

        &.btn2 {
          margin-left: 20px;
          background: linear-gradient(180deg, #FFF9D9 0%, #FFC750 100%);
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1), 0px 2px 8px 0px rgba(255, 255, 255, 0.7);
        }
      }
    }
  }
}

.pop {
  .title {
    height: 116px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 107, 68, 0.3) 100%);
    border-radius: 24px 24px 0px 0px;
    text-align: center;
    padding-top: 46px;
    position: relative;

    >span {
      font-size: 36px;
      padding: 0px 7px;
      color: #452C29;
      font-weight: 600;
      z-index: 1000;
      background-image: url(https://img.chaolu.com.cn/ACT/flag-202404/title-bg.png);
      background-size: 160px 18px;
      background-repeat: no-repeat;
      background-position: left bottom;
    }
  }

  .font {
    padding: 40px 0px;
    text-align: center;
    color: #68413C;
    font-size: 28px;
  }

  .line {
    background-color: #F5F5F5;
    width: 100%;
    height: 16px;
  }

  .detail {
    padding: 49px 32px;
    padding-bottom: 200px;
    display: flex;

    img {
      width: 160px;
      height: 160px;
      margin-right: 40px;
    }

    >div {
      color: #242831;

    }
  }

  .rule {
    padding: 20px 52px;
    font-size: 26px;
    color: #242831;
    line-height: 36px;

    p+p {
      margin-top: 40px;
    }
  }
}

.first-pop {
  width: 620px;
  height: 693px;
  background-color: transparent;
  overflow: visible;

  .first {
    width: 100%;
    height: 100%;
  }

  .close {
    width: 60px;
    height: 60px;
    position: absolute;
    bottom: -120px;
    transform: translateX(-330px);
  }

  &.van-popup--center {
    top: 45%;
  }

}

.flag-pop {
  height: 100vh;
  width: 100%;
  background-color: transparent;

  .title {
    padding-top: 190px;
    text-align: center;

    >img {
      width: 592px;
    }
  }

  .b-box {
    margin-top: 83px;
    width: 100%;

    >span {
      display: inline-block;
      color: #fff;
      text-align: center;
      margin-left: 20px;
      background: url(https://img.chaolu.com.cn/ACT/flag-202404/bubble-nocheck.png) top/100% auto no-repeat;

      &.check {
        background: url(https://img.chaolu.com.cn/ACT/flag-202404/bubble-check.png) top/100% auto no-repeat;
      }

      &:nth-child(1) {
        width: 150px;
        height: 150px;
        font-size: 24px;
        line-height: 150px;
        margin-left: 130px;
        animation: slide-top 5s linear infinite alternate;
      }

      &:nth-child(2) {
        width: 180px;
        height: 180px;
        font-size: 28px;
        line-height: 180px;
        margin-left: 10px;
        transform: translateY(-30px);
        animation: slide-top 2s linear infinite 2s alternate;
      }

      &:nth-child(3) {
        width: 130px;
        height: 130px;
        font-size: 20px;
        line-height: 130px;
        animation: slide-top 4s linear infinite 0.5s alternate;
      }

      &:nth-child(4) {
        width: 180px;
        height: 180px;
        font-size: 28px;
        line-height: 180px;
        animation: slide-top 5s linear infinite 2s alternate;
      }

      &:nth-child(5) {
        width: 150px;
        height: 150px;
        font-size: 24px;
        line-height: 150px;
        transform: translateY(-30px);
        animation: slide-top 2s linear infinite 0.5s alternate;
      }

      &:nth-child(6) {
        width: 180px;
        height: 180px;
        font-size: 28px;
        line-height: 180px;
        margin-left: 10px;
        animation: slide-top 5s linear infinite 2.5s alternate;
      }

      &:nth-child(7) {
        width: 130px;
        height: 130px;
        font-size: 20px;
        line-height: 130px;
        animation: slide-top 4s linear infinite 1s alternate;
      }

      &:nth-child(8) {
        width: 130px;
        height: 130px;
        font-size: 20px;
        line-height: 130px;
        margin-left: 100px;
        animation: slide-top 5s linear infinite 2s alternate;
      }

      &:nth-child(9) {
        width: 180px;
        height: 180px;
        font-size: 28px;
        line-height: 180px;
        margin-left: 10px;
        transform: translateY(-20px);
        animation: slide-top 5s linear infinite alternate;
      }

      &:nth-child(10) {
        width: 190px;
        height: 190px;
        font-size: 28px;
        line-height: 190px;
        margin-left: 20px;
        transform: translateY(20px);
        animation: slide-top 3s linear infinite 1s alternate;
      }

      &:nth-child(11) {
        width: 180px;
        height: 180px;
        font-size: 28px;
        line-height: 180px;
        margin-left: 190px;
        animation: slide-top 3s linear infinite 2s alternate;
      }

      &:nth-child(12) {
        width: 130px;
        height: 130px;
        font-size: 20px;
        line-height: 130px;
        margin-left: 40px;
        transform: translateY(-20px);
        animation: slide-top 2s linear infinite 1s alternate;
      }
    }
  }

  .btns {
    margin-top: 70px;
    display: flex;
    justify-content: center;

    >span {
      display: inline-block;
      width: 280px;
      height: 90px;
      border-radius: 20px;
      text-align: center;
      font-size: 40px;
      font-weight: 600;
      line-height: 90px;

      &.btn1 {
        color: #fff;
        border: 2px solid #fff;
      }

      &.btn2 {
        color: #412661;
        background: linear-gradient(0deg, #DBBDFF 0%, #F4EBFF 100%);
        margin-left: 30px;
      }
    }
  }
}


@bei: 1px;

.canvascss {
  position: fixed;
  bottom: -200%;
  // top: 0px;
  width: @bei*654;
  height: @bei*1160;
  margin: 0 auto;
  box-sizing: border-box;

  .canvascss_bg {
    width: 100%;
    height: 100%;
  }

  .canvascss_left {
    position: absolute;
    left: @bei*32;
    bottom: @bei*36;
    z-index: 1;
    width: @bei*100;
    height: @bei*100;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .canvascss_name {
    position: absolute;
    left: @bei*160;
    bottom: @bei*122;
    z-index: 1;
    color: #242831;
    font-weight: bold;
    font-size: @bei*28;
    width: @bei*300;
  }

  .canvascss_right {
    position: absolute;
    right: @bei*32;
    bottom: @bei*34;
    width: @bei*120;
    height: @bei*120;
    border-radius: @bei*14;
    overflow: hidden;
    padding: @bei*8;
    box-sizing: border-box;
    background-color: #7100E6;

    img {
      width: @bei*104;
      height: @bei*104;
      border-radius: @bei*6;
    }
  }
}
</style>
